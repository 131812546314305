
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "PredonorFilters",
  props: {
    applied_filters: Object,
    allowed_filters: {
      type: Array,
      required: false,
      default: () => {
        return [
          "type",
          "status",
          "created_at",
          "firstName",
          "lastName",
          "phone"
        ];
      },
    },
  },
  data() {
    return {
      statusOptions: {
        pending: "Pendiente",
        rejected: "Rechazado",
        approved: "Aprobados",
      },
      filters: {
        type: "",
        created_at: [],
        status: "",
        firstName: "",
        lastName: "",
        phone: "",
      },
    };
  },
  emits: ["changed"],
  methods: {
    resetFilters() {
      this.filters.type = "";
      this.filters.created_at = [];
      this.filters.status = "";
      this.filters.firstName = "";
      this.filters.lastName = "";
      this.filters.phone = "";
      this.dataChanged();
    },
    dataChanged() {
      this.$emit("changed", this.filters);
    },
  },
  mounted() {
    watch(this.filters, () => {
      this.dataChanged();
    });

    (this.filters as any) = this.applied_filters;

  },
});
